<template>
  <v-container grid-list-lg fluid>
    <v-layout row wrap>
      <v-flex>
        <StatCard
          v-model="connectionsTotal"
          title="Total Connections"
          color="warning"
          icon="account_circle"
        />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs6>
        <StatCard
          v-model="userTotal"
          title="Total Users"
          color="info"
          icon="account_circle"
          class="mb-3"
        />
        <v-card>
          <v-card-title class="info" dark>
            <span class="white--text card-title">10 Newest Users</span>
          </v-card-title>
          <v-card-text class="text-xs-left">
            <v-data-table
              :headers="usersHeaders"
              :items="newUsers"
              class="elevation-0"
              hide-actions
            >
              <template v-slot:items="props">
                <router-link :to="{ path: '/users/' + props.item.id }" tag="tr">
                  <td>{{ props.item.internal_id }}</td>
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ datetimeString(props.item.time) }}</td>
                </router-link>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs6>
        <StatCard
          v-model="teamTotal"
          title="Total Teams"
          color="success"
          icon="people"
          class="mb-3"
        />
        <FileImportCard
          id="null"
          :source="pdf"
          name="Upload Linkedin PDFs"
          accepts=".pdf, .zip"
          type="pdfs"
        />
        <v-card class="mb-3">
          <v-card-title class="success" dark>
            <span class="white--text card-title">5 Newest Teams</span>
          </v-card-title>
          <v-card-text class="text-xs-left">
            <v-data-table
              :headers="teamsHeaders"
              :items="newTeams"
              class="elevation-0"
              hide-actions
            >
              <template v-slot:items="props">
                <router-link
                  :to="{ path: '/accounts/' + props.item.id }"
                  tag="tr"
                >
                  <td>{{ props.item.internal_id }}</td>
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ datetimeString(props.item.time) }}</td>
                  <td class="text-xs-right">
                    {{ $n(props.item.users) }}
                  </td>
                </router-link>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title class="success" dark>
            <span class="white--text card-title">Top 5 Teams</span>
          </v-card-title>
          <v-card-text class="text-xs-left">
            <v-data-table
              :headers="topTeamsHeaders"
              :items="topTeams"
              class="elevation-0"
              hide-actions
            >
              <template v-slot:items="props">
                <router-link
                  :to="{ path: '/accounts/' + props.item.id }"
                  tag="tr"
                >
                  <td>{{ props.item.internal_id }}</td>
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.name }}</td>
                  <td class="text-xs-right">
                    {{ $n(props.item.users) }}
                  </td>
                </router-link>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import DashboardApi from "@/api/dashboard";
import { mapState, mapActions } from "vuex";
import StatCard from "@/components/StatCard";
import FileImportCard from "@/components/users/FileImportCard";

const { DateTime } = require("luxon");

export default {
  name: "Dashboard",
  components: {
    StatCard,
    FileImportCard
  },
  data() {
    return {
      connectionsTotal: 0,
      newTeams: [],
      newUsers: [],
      teamsHeaders: [
        { text: "ID", value: "id", sortable: false },
        { text: "Hash ID", value: "hash_id", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Time", value: "time", sortable: false },
        { text: "Users", value: "users", sortable: false, align: "right" },
      ],
      teamTotal: 0,
      topTeams: [],
      topTeamsHeaders: [
        { text: "ID", value: "id", sortable: false },
        { text: "Hash ID", value: "hash_id", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Users", value: "users", sortable: false, align: "right" },
      ],
      usersHeaders: [
        { text: "ID", value: "id", sortable: false },
        { text: "Hash ID", value: "hash_id", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Time", value: "time", sortable: false },
      ],
      userTotal: 0,
    };
  },
  computed: {
    ...mapState(["fetchingData", "error"]),
    pdf(){
      return require("@/assets/pdf.png");
    }
  },
  mounted() {
    this.fetchTotals();
    this.fetchUsers();
    this.fetchTeams();
  },
  methods: {
    ...mapActions(["fetchProfile"]),
    datetimeString(datetime) {
      if (datetime == null) {
        return "Not set.";
      }
      return DateTime.fromISO(datetime, { zone: "utc" })
        .toLocal()
        .toLocaleString(DateTime.DATETIME_MED);
    },
    fetchTeams() {
      DashboardApi.fetchTeams().then((resp) => {
        this.newTeams = resp.data.new_teams;
        this.topTeams = resp.data.top_teams;
      });
    },
    fetchTotals() {
      DashboardApi.fetchTotals().then((resp) => {
        this.userTotal = resp.data.users;
        this.teamTotal = resp.data.teams;
      });
      DashboardApi.fetchTotalConnections().then((resp) => {
        this.connectionsTotal = resp.data.total.connections;
      });
    },
    fetchUsers() {
      DashboardApi.fetchNewUsers().then((resp) => {
        this.newUsers = resp.data;
      });
    },
  },
};
</script>
