<template>
  <v-container grid-list-lg fluid>
    <v-breadcrumbs :items="breadcrumbs" divider="/" />
    <v-layout>
      <v-flex xs12>
        <h1 class="display-1">
          {{ account.name }}
          <v-btn flat color="primary" @click="showEditDialog">
            <v-icon left small> edit </v-icon>Edit
          </v-btn>
        </h1>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex sm12 lg8>
        <h1>
          Users
          <v-btn flat color="primary" @click="showAddUserDialog">
            <v-icon left small> person_add </v-icon>Add
          </v-btn>
        </h1>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          solo
          single-line
        />
        <v-card>
          <MembersTable
            :users="users"
            :search="search"
            @remove="requestRemoveUser($event)"
          />
        </v-card>
        <v-layout row>
          <v-flex v-if="isSuperAdmin" xs12 lg6>
            <v-btn
              class="elevation-0"
              color="rubber"
              large
              dark
              @click="showDeleteDialog"
            >
              Delete Team
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex lg4>
        <ContactTotals :id="id" />
      </v-flex>
    </v-layout>
    <AddUserToAccountDialog
      v-model="addUserDialog"
      :account="account"
      @add="addUser($event)"
      @close="hideAddUserDialog"
    />
    <EditAccountDialog
      v-model="editDialog"
      :account="account"
      @update="updateAccount($event)"
      @close="hideEditDialog"
    />
    <v-dialog v-model="deleteDialog" width="290">
      <DeleteForm
        title="Confirm Delete"
        @requestDelete="requestDelete()"
        @cancel="requestDeleteFormClose"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AccountApi from "@/api/accounts";
import AddUserToAccountDialog from "../components/accounts/AddUserToAccountDialog";
import ContactTotals from "../components/accounts/ContactTotals";
import DeleteForm from "../components/DeleteForm";
import EditAccountDialog from "../components/accounts/EditAccountDialog";
import MembersTable from "../components/members/MembersTable";

export default {
  name: "AccountDetail",
  components: {
    AddUserToAccountDialog,
    ContactTotals,
    DeleteForm,
    EditAccountDialog,
    MembersTable,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editDialog: false,
      addUserDialog: false,
      deleteDialog: false,
      account: {},
      users: [],
      search: "",
      breadcrumbs: [
        {
          text: "Teams",
          disabled: false,
          href: "/accounts",
        },
        {
          text: this.id,
          disabled: false,
          href: "/accounts/" + this.id,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isSuperAdmin"]),
    accountName() {
      if (this.account == null) {
        return "";
      }
      return this.account.name;
    },
  },
  created() {
    this.requestFetchAccount();
    this.requestFetchUsers();
  },
  methods: {
    addUser(user) {
      this.users = this.users.filter((x) => x.id !== user.id);
      this.users.push(user);
    },
    hideAddUserDialog() {
      this.addUserDialog = false;
    },
    hideEditDialog() {
      this.editDialog = false;
    },
    requestDelete() {
      AccountApi.deleteAccount(this.account.id).then((_resp) => {
        this.$notify({
          group: "notifs",
          title: "Account Deleted",
          type: "success",
        });
        this.$router.push("/accounts");
      });
    },
    requestDeleteFormClose() {
      this.deleteDialog = false;
    },
    requestFetchAccount() {
      AccountApi.fetchAccount(this.id).then((resp) => {
        this.$set(this, "account", resp.data);
      });
    },
    requestFetchUsers() {
      AccountApi.fetchUsers(this.id).then((resp) => {
        this.$set(this, "users", resp.data);
      });
    },
    requestRemoveUser(user) {
      AccountApi.removeUserRole(this.id, user.id).then((_resp) => {
        this.users = this.users.filter((x) => x.id !== user.id);
      });
    },
    showAddUserDialog() {
      this.addUserDialog = true;
    },
    showDeleteDialog() {
      this.deleteDialog = true;
    },
    showEditDialog() {
      this.editDialog = true;
    },
    updateAccount(account) {
      this.account = Object.assign(this.account, account);
      this.$notify({
        group: "notifs",
        title: "Account Updated",
        type: "success",
      });
    },
  },
};
</script>
