<template>
  <v-dialog
    v-model="open"
    width="390"
    style="overflow: auto; -webkit-overflow-scrolling: touch;"
    @keydown.esc="close"
  >
    <v-card class="pa-2">
      <v-card-title class="card-title">
        Add User to Team
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="account"
          :items="accounts"
          :loading="isLoading"
          :search-input.sync="search"
          color="primary"
          hide-no-data
          hide-selected
          item-text="name"
          item-value="id"
          label="Accounts"
          placeholder="Search for team..."
          prepend-icon="domain"
          return-object
        />
        <v-select
          v-model="role"
          :items="roles"
          item-text="title"
          item-value="value"
          label="Role"
          prepend-icon="account_circle"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          ref="saveButton"
          color="primary"
          class="elevation-0"
          dark
          @click.native="save"
        >
          Save
        </v-btn>
        <v-btn ref="cancelButton" color="primary" flat @click.native="close">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccountApi from "@/api/accounts";

export default {
  name: "AddAccountToUserDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      open: this.value,
      errors: [],
      role: "owner",
      accounts: [],
      account: null,
      search: "",
      roles: [
        { title: "Owner", value: "owner" },
        { title: "Admin", value: "admin" },
        { title: "Member", value: "member" },
      ],
    };
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
    open: {
      handler() {
        if (!this.open) {
          this.close();
        }
      },
    },
    search(val) {
      if (val === "") {
        this.users = [];
      }
      val && val !== this.select && this.querySelections();
    },
  },
  methods: {
    save() {
      this.requestAddRole(this.account.id, this.user.id, this.role);
    },
    querySelections() {
      this.loading = true;

      AccountApi.searchAccounts(this.search).then((resp) => {
        this.accounts = resp.data;
      });
    },
    requestAddRole(accountId, userId, role) {
      this.isLoading = true;
      AccountApi.addOrUpdateUserRole(accountId, userId, role)
        .then((_resp) => {
          this.account.role = role;
          this.errors = [];
          this.$emit("add", this.account);
          this.$emit("close");
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        })
        .finally(() => (this.isLoading = false));
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
