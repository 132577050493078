<template>
  <v-app>
    <AppHeader
      v-if="!(['Login'].indexOf(this.$route.name) > -1)"
      @toggleDrawer="drawer = !drawer"
    />
    <AppSidebar
      v-if="
        !(['Forbidden'].indexOf(this.$route.name) > -1) &&
        !(['Login'].indexOf(this.$route.name) > -1)
      "
      :drawer="drawer"
      :clipped="clipped"
    />
    <v-content>
      <v-container fluid>
        <router-view :key="$route.path"></router-view>
      </v-container>
    </v-content>
    <AppFooter />
    <notifications group="notifs" position="bottom center" width="100%" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

import AppFooter from "@/components/AppFooter";
import AppSidebar from "@/components/AppSidebar";
import AppHeader from "@/components/AppHeader";

export default {
  name: "App",
  components: { AppFooter, AppHeader, AppSidebar },
  data() {
    return {
      drawer: true,
      clipped: true,
      right: null,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
};
</script>

<style lang="scss">
#app {
  background-color: transparent;
  font-family: "Carme", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  height: 100%;
}

html {
  background: #2c2c2c;
}

body {
  background-color: #f2f2f2;
  margin: 0;
  font-family: "Carme", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-title {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.bold {
  font-weight: bold;
}
</style>
