import axios from "axios";
import Utils from "@/api/utils";

export default {
  fetchTotals() {
    let url = Utils.accountApiUrl(`/v1/admin/totals`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchNewUsers() {
    let url = Utils.accountApiUrl(`/v1/admin/new_users`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchTeams() {
    let url = Utils.accountApiUrl(`/v1/admin/teams`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchTotalConnections() {
    let url = Utils.contactApiUrl(`/v1/reports/`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
};
