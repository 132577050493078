<template>
  <v-layout row>
    <v-flex xs12>
      <v-card>
        <v-list two-line dense>
          <v-list-tile>
            <img :src="icon" class="mr-4" />
            <v-list-tile-content class="bigger_font">
              {{ account.email }}
            </v-list-tile-content>
            <v-spacer />
            <v-btn color="elevation-0 primary" small @click="submitImport">
              Import
            </v-btn>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
const { DateTime } = require("luxon");

import Api from "@/api/import";

export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    datetimeString(datetime) {
      if (datetime == null) {
        return "Not set.";
      }
      return DateTime.fromISO(datetime, { zone: "utc" })
        .toLocal()
        .toLocaleString(DateTime.DATETIME_MED);
    },
    submitImport() {
      let importTypes = {
        google: "google",
        office: "microsoft",
      };

      Api.submitOAuthImport(
        this.account.email,
        importTypes[this.account.provider],
        this.id
      )
        .then((resp) => {
          if (!resp.success) {
            this.notifyError(resp.error.message);
          } else {
            this.notifySuccess();
          }
        })
        .catch((_error) => {
          this.notifyError();
        });
    },
    notifyError(msg = "If this issue persists, please contact support.") {
      this.$notify({
        group: "notifs",
        title: "Request Failed",
        text: msg,
        type: "error",
      });
    },
    notifySuccess() {
      this.$notify({
        group: "notifs",
        title: "Success",
        text: "Contact import started.",
        type: "success",
      });
    },
  },
};
</script>
