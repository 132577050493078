import axios from "axios";
import Utils from "@/api/utils";

export default {
  fetchAccounts(params) {
    let url = Utils.accountApiUrl("/v1/accounts");

    return axios
      .get(url, { params: params, headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  searchAccounts(q) {
    let url = Utils.accountApiUrl(`/v1/accounts?q=${q}`);

    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchAccount(accountId) {
    let url = Utils.accountApiUrl(`/v1/accounts/${accountId}`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  createAccount(account) {
    let url = Utils.accountApiUrl(`/v1/accounts`);
    return axios
      .post(url, account, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  updateAccount(account) {
    let url = Utils.accountApiUrl(`/v1/accounts/${account.id}`);

    return axios
      .put(url, account, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchUsers(accountId) {
    let url = Utils.accountApiUrl(`/v1/accounts/${accountId}/users`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  addOrUpdateUserRole(accountId, userId, role) {
    let url = Utils.accountApiUrl(
      `/v1/accounts/${accountId}/users/${userId}?role=${role}`
    );
    return axios
      .put(url, {}, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  removeUserRole(accountId, userId) {
    let url = Utils.accountApiUrl(`/v1/accounts/${accountId}/users/${userId}`);
    return axios
      .delete(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  deleteAccount(accountId) {
    let url = Utils.accountApiUrl(`/v1/accounts/${accountId}`);
    return axios
      .delete(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },

  fetchContactTotals(accountId) {
    let url = Utils.contactApiUrl(`/v1/accounts/${accountId}/statistics`);
    return axios
      .get(url, { headers: Utils.authHeaders() })
      .then((resp) => resp.data);
  },
};
