<template>
  <v-card class="elevation-0 transparent">
    <v-card-title class="card-title">
      <v-icon class="mr-3">
        insert_chart
      </v-icon>
      Contacts Imported
      <v-spacer />
    </v-card-title>
    <v-layout row>
      <TotalCard :source="outlook" :total="outlookTotal" />
    </v-layout>
    <v-layout row>
      <TotalCard :source="google" :total="googleTotal" />
    </v-layout>
    <v-layout row>
      <TotalCard :source="linkedIn" :total="linkedinTotal" />
    </v-layout>
    <v-layout row>
      <TotalCard :source="office" :total="microsoftTotal" />
    </v-layout>
    <v-layout row>
      <TotalCard :source="apple" :total="appleTotal" />
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <v-card class="elevation-0 transparent">
          <v-list class="transparent" two-line dense>
            <v-list-tile>
              <v-card-title class="card-title">
                Total
              </v-card-title>
              <v-list-tile-content class="bigger_font">
                {{ $n(total) }}
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import AccountApi from "@/api/accounts";
import TotalCard from "@/components/accounts/TotalCard";

export default {
  components: { TotalCard },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      outlookTotal: 0,
      googleTotal: 0,
      linkedinTotal: 0,
      microsoftTotal: 0,
      appleTotal: 0,
      total: 0,
    };
  },
  computed: {
    outlook() {
      return require("@/assets/outlook-logo.png");
    },
    google() {
      return require("@/assets/google.png");
    },
    linkedIn() {
      return require("@/assets/linkedin-logo-transparent.png");
    },
    office() {
      return require("@/assets/office-logo-transparent.png");
    },
    apple() {
      return require("@/assets/apple-logo-transparent.png");
    },
  },
  mounted() {
    this.setContactsImported();
  },
  methods: {
    setContactsImported() {
      AccountApi.fetchContactTotals(this.id).then((resp) => {
        this.outlookTotal = resp.data.total_imported.exchange;
        this.googleTotal = resp.data.total_imported.google;
        this.linkedinTotal = resp.data.total_imported.linkedin;
        this.appleTotal = resp.data.total_imported.icloud;
        this.microsoftTotal = resp.data.total_imported.microsoft;
        this.total = resp.data.total_imported.all;
      });
    },
  },
};
</script>

<style lang="scss">
.bigger_font {
  font-size: 15px;
}
</style>
