<template>
  <v-form @submit.prevent="requestEmailLogin">
    <v-layout row>
      <v-btn
        color="#4285F4"
        min-width="600"
        class="pl-1 pr-3"
        large
        dark
        block
        @click="requestGoogleLogin"
      >
        <img src="@/assets/google-logo.png" />
        <span class="button-label">Sign in with Google</span>
      </v-btn>
    </v-layout>
    <v-layout row>
      <v-btn
        color="#D53A00"
        class="pl-1 pr-3"
        large
        dark
        block
        @click="requestMicrosoftLogin"
      >
        <img src="@/assets/office-logo.png" />
        <span class="button-label">Sign in with Office 365</span>
      </v-btn>
    </v-layout>
    <DividerBar text="OR" />
    <v-text-field
      v-model="email"
      autocomplete="username"
      type="email"
      label="E-mail"
      placeholder="you@example.com"
      required
      solo
    />
    <v-text-field
      v-model="password"
      autocomplete="current-password"
      label="Password"
      type="password"
      required
      solo
    />
    <v-btn type="submit" color="primary" large dark block>
      Login
    </v-btn>
    <v-btn large flat block @click="$emit('forgot-password')">
      Forgot password?
    </v-btn>
  </v-form>
</template>

<script>
import DividerBar from "@/components/DividerBar";

export default {
  name: "LoginForm",
  components: {
    DividerBar,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    requestEmailLogin() {
      this.$emit("log-in-email", {
        email: this.email,
        password: this.password,
      });
    },
    requestGoogleLogin() {
      this.$emit("log-in-google");
    },
    requestMicrosoftLogin() {
      this.$emit("log-in-microsoft");
    },
  },
};
</script>

<style lang="scss" scoped>
.button-logo {
  position: absolute;
  left: 8px;
}

.button-label {
  width: 100%;
}

div.btn__content {
  background-color: #f00 !important;
  margin: 16px;
}
</style>
