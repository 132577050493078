<template>
  <div class="login">
    <p class="card-title py-2">
      Forgot Password
    </p>
    <v-text-field
      v-model="email"
      type="email"
      label="E-mail"
      placeholder="you@example.com"
      solo
    />
    <v-btn
      :disabled="!hasEmail"
      color="primary"
      large
      block
      @click="$emit('forgot-password', email)"
    >
      Request Password Reset
    </v-btn>
    <p class="text-xs-center my-3">
      Know your password?
      <a href="javascript:undefined" @click="toggleLogin">Log in.</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "ForgotPasswordForm",
  data() {
    return {
      email: "",
    };
  },
  computed: {
    hasEmail() {
      return this.email != null && this.email.length > 0;
    },
  },
  methods: {
    toggleLogin() {
      this.$emit("log-in");
    },
  },
};
</script>
