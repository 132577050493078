// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import Vuetify from "vuetify";
import VueCookie from "vue-cookie";
import Notifications from "vue-notification";

import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

import App from "./App";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import WhiteLabelPlugin from "./whitelabel-plugin";
Vue.use(WhiteLabelPlugin);

Vue.use(Vuetify, {
  theme: {
    black: "#181818",
    primary: "#dd4b39",
    secondary: "#3766fd",
    navy: "#12253e",
    darkNavy: "#0c1623",
    rubber: "#424242",
    beige: "#f2f2f2",
    error: "#d32f2f",
    success: "#388e3c",
    info: "#1976d2",
    warning: "#f57c00",
  },
});

Vue.use(VueCookie);
Vue.use(Notifications);

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { version } from "../package.json";
import VueGtag from "vue-gtag";

import whiteLabelConfig from "@/whitelabel.config";
let config = whiteLabelConfig[process.env.VUE_APP_BRAND];

if (process.env.VUE_APP_BRAND == "prod" || process.env.VUE_APP_BRAND == "dev") {
  Vue.use(
    VueGtag,
    {
      config: { id: config.ganalyticsId },
    },
    router
  );

  Sentry.init({
    Vue,
    autoSessionTracking: true,
    dsn: "https://c8ab47b6e912487a9dd89f1bba9d4b05@o235257.ingest.sentry.io/6457302",
    environment: process.env.VUE_APP_BRAND,
    integrations: [new Integrations.BrowserTracing()],
    release: `admin-web@${version}`,
    tracesSampleRate: 1.0,
  });
}

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  router,
  store,
  components: { App },
  i18n,
  render: (h) => h(App),
}).$mount("#app");
