<template>
  <v-dialog
    v-model="open"
    width="390"
    style="overflow: auto; -webkit-overflow-scrolling: touch;"
    @keydown.esc="close"
  >
    <v-card class="pa-2">
      <v-card-title class="card-title">
        Edit Account
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="tempAccount.name"
          :error-messages="errors['name']"
          label="Name"
          prepend-icon="domain"
          required
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          ref="saveButton"
          color="primary"
          class="elevation-0"
          dark
          @click.native="save"
        >
          Update
        </v-btn>
        <v-btn ref="cancelButton" color="primary" flat @click.native="close">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-progress-linear v-if="isLoading" class="ma-0" indeterminate />
  </v-dialog>
</template>

<script>
import AccountApi from "@/api/accounts";

export default {
  name: "EditAccountDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      open: this.value,
      tempAccount: { ...this.account },
      errors: [],
    };
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
    account: {
      handler() {
        this.tempAccount = { ...this.account };
      },
    },
    open: {
      handler() {
        if (!this.open) {
          this.close();
        }
      },
    },
  },
  methods: {
    save() {
      this.requestUpdateAccount(this.tempAccount);
    },
    requestUpdateAccount(account) {
      this.isLoading = true;
      AccountApi.updateAccount(account)
        .then((resp) => {
          this.errors = [];
          this.$emit("update", resp.data);
          this.$emit("close");
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        })
        .finally(() => (this.isLoading = false));
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
