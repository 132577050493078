<template>
  <v-card>
    <v-card-title :class="color" dark>
      <v-icon left dark>
        {{ icon }}
      </v-icon>
      <h1 class="white--text">
        {{ $n(value) }}
      </h1>
      <v-spacer />
      <span class="white--text card-title">{{ title }}</span>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: "StatCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "grey",
    },
    icon: {
      type: String,
      default: "account_circle",
    },
  },
};
</script>
