<template>
  <v-dialog
    v-model="open"
    width="390"
    style="overflow: auto; -webkit-overflow-scrolling: touch;"
    @keydown.esc="close"
  >
    <v-card class="pa-2">
      <v-card-title class="card-title">
        New Account
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          :error-messages="errors['name']"
          label="Name*"
          prepend-icon="domain"
          required
        />
        <v-text-field
          v-model="email"
          :error-messages="errors['email']"
          label="Email*"
          prepend-icon="mail"
          required
        />
        <v-label>* is required</v-label>
      </v-card-text>
      <v-card-actions>
        <v-btn
          ref="saveButton"
          color="primary"
          class="elevation-0"
          dark
          @click.native="save"
        >
          Create
        </v-btn>
        <v-btn ref="cancelButton" color="primary" flat @click.native="close">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-progress-linear v-if="loading" class="ma-0" indeterminate />
  </v-dialog>
</template>

<script>
import AccountApi from "@/api/accounts";

export default {
  name: "CreateAccountDialog",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      open: this.value,
      name: "",
      email: "",
      errors: [],
    };
  },
  watch: {
    value: {
      handler() {
        this.open = this.value;
      },
    },
    open: {
      handler() {
        if (!this.open) {
          this.close();
        }
      },
    },
  },
  methods: {
    save() {
      this.requestCreateAccount(this.name, this.email);
    },
    requestCreateAccount(name, email) {
      AccountApi.createAccount({ name: name, email: email })
        .then((resp) => {
          this.$emit("create", resp.data);
          this.$emit("close");
          this.$emit("update-accounts-table");
        })
        .finally(() => (this.isLoading = false));
    },
    close() {
      this.name = "";
      this.$emit("close");
    },
  },
};
</script>
